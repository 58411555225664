import React from 'react';
import styled from 'styled-components';
import ScrollableAnchor from 'react-scrollable-anchor';

import MapImg from 'assets/images/map.png';

const Container = styled.div`
  width: 100%;
  height: 630px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > :not(:first-child) {
    margin-left: 3rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10.5%;
  flex: 1;

  > *:not(:first-child) {
    margin-top: 1rem;
  }
`;

const HeaderText = styled.h1`
  color: #388e3c;
`;

const SummaryText = styled.h2`
  font-weight: normal;
  white-space: nowrap;
`;

const MapContainer = styled.div`
  position: relative;
  margin-right: 10.5%;
`;

const Map = styled.img`
  width: 500px;
  max-width: 100%;
  border-radius: 3px;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.2);
`;

const PulseLocator = styled.div`
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(56, 142, 60, 0.65);
    }
    70% {
      box-shadow: 0 0 0 40px rgba(56, 142, 60, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(56, 142, 60, 0);
    }
  }

  position: absolute;
  top: 52%;
  left: 52%;

  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #388e3c;

  box-shadow: 0 0 0 rgba(47, 137, 252, 0.5);
  animation: pulse 2s infinite;
`;

const LocationSection = (): JSX.Element => (
  <ScrollableAnchor id="location">
    <Container>
      <TextContainer>
        <HeaderText>Come Visit Us</HeaderText>
        <SummaryText>
          101 Hickey Blvd
          <br />
          South San Francisco, CA 94080
        </SummaryText>
      </TextContainer>
      <MapContainer>
        <Map src={MapImg} />
        <PulseLocator />
      </MapContainer>
    </Container>
  </ScrollableAnchor>
);

export default LocationSection;
