import React from 'react';

import { NavBar, Footer } from 'common';
import {
  AboutSection,
  ServicesSection,
  LocationSection,
  ContactUsSection
} from 'containers/sections';

class Home extends React.Component {
  public render = (): JSX.Element => (
    <React.Fragment>
      <NavBar />
      <AboutSection />
      <ServicesSection />
      <LocationSection />
      <ContactUsSection />
      <Footer />
    </React.Fragment>
  );
}

export default Home;
