import React from 'react';
import styled from 'styled-components';
import { get, isNil } from 'lodash';

import LogoImg from 'assets/images/logo.png';

interface NavBarContainerProps {
  scrolled: boolean;
}

const NavBarContainer = styled.div<NavBarContainerProps>`
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 1rem 3rem;
  z-index: 100;
  transition: 0.5s;
  box-shadow: ${(props): string =>
    props.scrolled ? '0px 5px 10px rgba(0, 0, 0, 0.2)' : 'none'};
  height: 7rem;
  box-sizing: border-box;
`;

const Logo = styled.img`
  height: 100%;
`;

const MenuOptions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin-left: 3rem;
  }
`;

const MenuText = styled.a`
  font-size: 1.2em;
`;

const MenuOption = styled(MenuText)`
  text-decoration: none;
  color: black;

  :hover {
    color: #388e3c;
  }
`;

const QuoteButton = styled.a`
  border: 1px solid #388e3c;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 4px;
  border-radius: 30px;
  text-decoration: none;

  a {
    color: #388e3c;
  }
`;

interface State {
  scrolled: boolean;
}

class NavBar extends React.Component<{}, State> {
  public state = { scrolled: false };

  public componentDidMount = (): void => {
    window.addEventListener('scroll', this.handleScroll);
  };

  public componentWillUnmount = (): void => {
    window.removeEventListener('scroll', this.handleScroll);
  };

  private handleScroll = (event: Event): void => {
    const scrollTop = get(event, 'target.scrollingElement.scrollTop');

    if (!isNil(scrollTop)) {
      this.setState({ scrolled: scrollTop > 10 });
    }
  };

  public render = (): JSX.Element => (
    <NavBarContainer scrolled={this.state.scrolled}>
      <Logo src={LogoImg} />
      <MenuOptions>
        <MenuOption href="#about">About</MenuOption>
        <MenuOption href="#merchandise">Merchandise</MenuOption>
        <MenuOption href="#location">Location</MenuOption>
        <MenuOption href="#contact">Contact</MenuOption>
        <QuoteButton href="mailto:sales@snapstardeals.com">
          <MenuText>Get A Quote</MenuText>
        </QuoteButton>
      </MenuOptions>
    </NavBarContainer>
  );
}

export default NavBar;
