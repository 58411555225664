import React from 'react';
import styled from 'styled-components';
import ScrollableAnchor from 'react-scrollable-anchor';

import IosImage from 'assets/images/feature.png';

const Container = styled.div`
  width: 100%;
  height: 630px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > :not(:first-child) {
    margin-left: 3rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10.5%;
  flex: 1;

  > *:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

const HeaderText = styled.h1`
  color: #388e3c;
`;

const SummaryText = styled.p`
  font-weight: normal;
`;

const QuoteButton = styled.a`
  background-color: #388e3c;
  border: 1px solid #388e3c;
  color: white;
  padding: 1rem;
  border-radius: 2rem;
  transition: 0.15s ease-in-out;
  text-decoration: none;

  :hover {
    background-color: white;
    color: #388e3c;
  }
`;

const IosImgContainer = styled.img`
  width: 60%;
`;

const AboutSection = (): JSX.Element => (
  <ScrollableAnchor id="about">
    <Container>
      <TextContainer>
        <HeaderText>Deals in a Snap</HeaderText>
        <SummaryText>
          SnapStar Deals specializes in sourcing your favorite electronics, collectibles,
          and more straight to you at the best possible prices. Look no further than us
          to find the best deals in a snap.
        </SummaryText>
        <QuoteButton href="mailto:sales@snapstardeals.com">Get A Quote</QuoteButton>
      </TextContainer>
      <IosImgContainer src={IosImage} />
    </Container>
  </ScrollableAnchor>
);

export default AboutSection;
